import React, {useState} from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import pricingLogo from "../images/pricingLogo.svg"
import { styled, alpha } from '@mui/system'
import SliderUnstyled from '@mui/base/SliderUnstyled'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Pricing = () => {
    const [qtyEvistampApi, setQtyEvistampApi] = useState(0)
    const [ qtyEvistampPro, setQtyEvistampPro] = useState(0)
    return (
        <Layout>
            <Seo title="Pricing" description="Pricing Programs" />
            <div>
                <section className="pricing-heading">
                    <div className='pricing-heading-main'>
                        <h3>Pricing</h3>
                        <h6>| Bundle super saver offer</h6>
                        <p>Buy the bundle you need, according to your projects. All purchased Evistamp PRO and EviStamps API must be used within 90 days from acquisition. In case of a new purchase, we will extend the existing unused Evistamps with same expiry date as the new bundle purchased.</p>
                    </div>
                </section>
            
                <section className="pricing-line">
                    <div className="pricing-line-main">
                        <article>
                            <img src={pricingLogo}/>
                        </article>
                        <article>
                            <div className="article-half">
                                <div className="evistampPro">
                                    <h3>Evistamp Pro</h3>
                                    <div className="box">
                                <p><span>10 Evistamps:</span> <b>$10</b> </p>
                                <p><span>50 Evistamps</span> <b>$47</b> </p>
                                <p><span>100 Evistamps</span> <b>$90</b> </p>
                            </div>
                                   
                                </div>
                                <div className="evistampApi">
                                    <h3>Evistamp API</h3>
                                    <div className="box">
                                <p><span>1000 Evistamps:</span><b> $100</b> </p>
                                <p><span>5000 Evistamps</span><b> $450</b> </p>
                                <p><span>10000 Evistamps</span> <b>$700</b> </p>
                            </div>
                                </div>
                            </div>
                            <div className="box-button">
                                    <button>Buy now</button>
                                    <p className="buy-now-text">To buy please register first.</p>
                                </div>
                        </article>
                    </div>
                </section>
            </div>
        </Layout>
    )
}
export default Pricing